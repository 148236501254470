import React, {Component} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,  withIonLifeCycle  } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import ReactPlayer from 'react-player'
import Select from 'react-select'

import './Tab3.css';
import axios from 'axios';
import parse from 'html-react-parser';

import 'react-dropdown/style.css';
import Dropdown from 'react-dropdown';

var playerPlaying = false;

//TODO QUERY PROVISIONING SERVER
//GET VOD LINK
//Parse directory.json
//will this save
var urlList = [];
var separatedList;
var vodCompleteUrls = [];
var vodDateNames = [];
var masterList = [];
var code;
var friendlyList = [];


class Tab3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stateChanger: "",
            archiveUrl: "",
            playing: false,
            dropDownList: [],
            dropDownOpen: false,
            looksgoodlist: [],
            playbackURL:""


        };

    }



   toggle = () => {
       this.setState({dropDownOpen: !this.state.dropDownOpen})

       console.log("Trying to open")

    };

    uiFriendlyVideoList = (vodBaseLink, mp4Array) => {

        //{ value: 'chocolate', label: 'Chocolate' }

        for(var i = 0; i<mp4Array.length; i++) {

            let cleanYear = mp4Array[i].slice(0,4)
            let cleanMonth = mp4Array[i].slice(5,7)
            let cleanDay = mp4Array[i].slice(8,10)
            let cleanTime = mp4Array[i].slice(11,13) + ":" + mp4Array[i].slice(14,16)
            console.log(cleanMonth)
            console.log(cleanYear)
            console.log(cleanDay)
            console.log(cleanTime)

            var niceLookingDate = cleanMonth + "/" + cleanDay + "/" + cleanYear + " " + cleanTime

            let vodStreamingLink = vodBaseLink + "/" + mp4Array[i];

            friendlyList.push({value: vodStreamingLink, label: niceLookingDate})

        }



        this.setState({looksgoodlist: friendlyList})

        console.log(this.state.dropDownList)


    }

    ionViewWillLeave() {
        console.log('ionViewWillLeave event fired')
        this.setState({playing:false})
        console.log("LEAVING TAB")
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    }

    componentDidMount() {
        var self = this;
        setTimeout(
            function() {
                self.checkPlayerStatus();
                //5 minutes on VOD videos
            }, 1000 * 60 * 5);
    }

    componentWillMount() {

       let link = localStorage.getItem('link');
       let vodLinkDirectory = localStorage.getItem('vodLinkDirectory')
        code = localStorage.getItem('idcode');
        let vodurldirectory;
        console.log(link)

        try {



            vodurldirectory = vodLinkDirectory + '/directory.json'

            console.log(vodurldirectory);

        }

        catch {

            alert("Please Enter Your Stream Code")

        }

        var self = this;

        axios.get(vodurldirectory)
            .then(function (response) {
                // handle success
              //  console.log(response);

                var pageDump = response;

               // console.log(pageDump.data);

                var htPage = pageDump.data;

           console.log(htPage);

                var mp4List = htPage.split(',');
                console.log(mp4List);
                mp4List.splice(mp4List.indexOf('directory.json'),1);
                console.log(mp4List)

                self.uiFriendlyVideoList(vodLinkDirectory, mp4List);

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {




            });

//        this.setState({stateChanger: "ABC"})






    }




    setUrl = (u) => {

        console.log(u);

this.setState({playbackURL : u.value})

    }

    checkPlayerStatus = () =>{

        console.log(playerPlaying);

        var streamIDCode;

        try {
            streamIDCode = localStorage.getItem('idcode');

        }

        catch (e) {

            console.log("Can't find a stream code");

        }

        if(playerPlaying === true) {

            //after 5 minutes call analytics server
            console.log("hanging in there")

            axios.post('https://analytics.relayfaith.com/stats', {
                orgID: streamIDCode
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });


        }


    }

    setPlayerTrue = () => {
console.log("Setting player true")
        playerPlaying = true

    }

    render() {

        const defaultOption = masterList[0];

        var PlayerWithReact = () => (

            <div  style={{'width':'75%', 'height': '75%',display: 'flex', justifyContent: 'center'}}>

                <ReactPlayer onPlay={() => this.setPlayerTrue()} className='react-player' url={this.state.playbackURL} playing={this.state.playing} file='forceHLS' controls="true" width="100%" height="100%" />

            </div>


        )


        return(

            <div >
                <IonPage>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Relay Faith</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <IonHeader collapse="condense">
                            <IonToolbar>
                                <IonTitle size="large">Archives - Last 30 days</IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <div>
                            <PlayerWithReact/>

                            <Select isSearchable={ false } onChange={e => this.setUrl(e)} options={this.state.looksgoodlist} />

                        </div>

                    </IonContent>
                </IonPage>
            </div>
        )
            ;

    }
}


export default withIonLifeCycle(Tab3);
