import React, {Component} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, withIonLifeCycle  } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab2.css';
import ReactPlayer from 'react-player'
import {Form, FormGroup, Input, Label} from "reactstrap";
import axios from "axios";



var link;
var orgName;

class Tab2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stateChanger: "",
            playing: true


        };

    }

    ionViewWillLeave() {
        console.log('ionViewWillLeave event fired')
        this.setState({playing:false})

        console.log("LEAVING TAB")
    }



    componentDidMount() {

       link = localStorage.getItem('link');

console.log(link)

         // this.setState({stateChanger: "abc"})
        var self = this;
        setTimeout(
            function() {
               self.checkPlayerStatus();
               //10 minutes
            }, 1000 * 60 * 10);

    }


checkPlayerStatus = () =>{

        var streamIDCode;

        try {
            streamIDCode = localStorage.getItem('idcode');

        }

        catch (e) {

            console.log("Can't find a stream code");

        }



        if(this.state.playing === true) {

            //after 10 minutes call analytics server
            console.log("hanging in there")

            axios.post('https://analytics.relayfaith.com/stats', {
                orgID: streamIDCode
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });


        }


}



    render() {


      //  console.log(link)




        return(

            <div >
                <IonPage>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Relay Faith</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <IonHeader collapse="condense">
                            <IonToolbar>
                                <IonTitle size="large">Relay Faith</IonTitle>
                            </IonToolbar>
                        </IonHeader>
                        <div  style={{'width':'75%', 'height': '75%',display: 'flex', justifyContent: 'center'}}>
                            <ReactPlayer className='react-player' url={link} playing={this.state.playing} file='forceHLS' controls="true" width="100%" height="100%" />

                        </div>
                    </IonContent>
                </IonPage>
            </div>
        )
            ;

    }
}


export default withIonLifeCycle(Tab2);
