import React, {Component} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonInput, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Tab1.css';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import axios from 'axios';



class Tab1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stateChanger: "",
            orgID: "Stream Code"


        };

    }

    componentWillMount() {




var restoreCode = localStorage.getItem('idcode');

if (restoreCode === undefined || restoreCode == null){

}

else{this.setState({orgID:restoreCode})}


    }

    getStreamURL = () => {

        let unformattedStreamCode = this.state.orgID;

        let lowerCaseorgID = unformattedStreamCode.toLowerCase();
//used to be streamauth.maconapps.com
        axios.post(`https://lookup.relayfaith.com`,  {'streamID':lowerCaseorgID} )
            .then(res => {
                //console.log(res);
                console.log(res.data);

                if(res.data === "ID NOT FOUND") {

                    alert('STREAM ID NOT FOUND')

                }

                else {

                    var datum = res.data;

                    console.log(datum);

                    //var link = datum.link;
                    let link = datum.link;
                    console.log(link)
                    let vodLinkDirectory = datum.vodLinker;

                   // ls.set('link', link)
                    //ls.set('orgname', orgName)

                    localStorage.setItem('link', link);

                    console.log(link);
                    localStorage.setItem('vodLinkDirectory',vodLinkDirectory)
                    localStorage.setItem('idcode',lowerCaseorgID)


                    window.location.href = '/tab2'






                   //
                }
            })

    }

    getArchiveURL = () => {

        let unformattedStreamCode = this.state.orgID;

        let lowerCaseorgID = unformattedStreamCode.toLowerCase();
//used to be streamauth.maconapps.com
        axios.post(`https://lookup.relayfaith.com`,  {'streamID':lowerCaseorgID} )
            .then(res => {
                //console.log(res);
                console.log(res.data);

                if(res.data === "ID NOT FOUND") {

                    alert('STREAM ID NOT FOUND')

                }

                else {

                    var datum = res.data;

                    console.log(datum);

                    //var link = datum.link;
                    let link = datum.link;
                    console.log(link)
                    let vodLinkDirectory = datum.vodLinker;

                    // ls.set('link', link)
                    //ls.set('orgname', orgName)

                    localStorage.setItem('link', link);

                    console.log(link);
                    localStorage.setItem('vodLinkDirectory',vodLinkDirectory)
                    localStorage.setItem('idcode',lowerCaseorgID)


                    window.location.href = '/tab3'






                    //
                }
            })

    }
//e => this.setState({orgID: e.target.value })

render() {

    return(

        <div >
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Relay Faith</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large">Select Stream</IonTitle>
                        </IonToolbar>
                    </IonHeader>


                <Form style={{display: 'flex', justifyContent: 'center', 'marginTop' : '15%'}}>
                        <FormGroup>
                            <Input type="text" autocomplete='off' autocorrect='off' autocapitalize='off' name="email" id="exampleEmail" placeholder={this.state.orgID} onKeyPress={e => {
                                if (e.key === 'Enter') e.preventDefault();}} onChange={e => this.setState({orgID: e.target.value })} />
                                <div style={{display: 'flex', justifyContent: 'center',  'marginTop' : '5%'}}>
                            <Button  onClick={()=>this.getStreamURL()}>Stream</Button>
                            <Button style={{marginLeft: '12px'}} onClick={()=>this.getArchiveURL()}>Archives</Button>
                                </div>
                </FormGroup>

                    </Form>



                </IonContent>
            </IonPage>
        </div>
    )
        ;

}
}


export default Tab1;
